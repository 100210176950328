import intlTelInput, { getCountryData } from 'intl-tel-input'

export const initPhoneInputs = () => {
  const forms = document.querySelectorAll('form')
  forms.forEach(form => {
    fetchGeoIp()
      .then(countryCode => {
        initFormWithCountry(form, countryCode)
      })
      .catch(() => {
        initFormWithCountry(form, 'us')
      })
  })
}

const fetchGeoIp = () =>
  fetch('https://ipinfo.io/json')
    .then(resp => resp.json())
    .then(data => (data && data.country ? data.country : 'us'))

const initFormWithCountry = (form, countryCode) => {
  const telInputs = form.querySelectorAll("input[type='tel']")
  const countryDropdown = form.querySelector('select[data-umb]')
  const instanceMap = []
  telInputs.forEach(input => {
    const iti = intlTelInput(input, {
      placeholderNumberType: 'MOBILE',
      autoPlaceholder: 'polite',
      utilsScript: '/dist/utils.js',
      separateDialCode: false,
      initialCountry: countryCode
    })
    instanceMap.push({ input, iti })
    setDefaultDialCode(input, iti)
    attachInputEvents(input, iti, countryDropdown, form)
  })
  if (telInputs.length) {
    const selectedCountry = instanceMap[0].iti.getSelectedCountryData()
    updateHiddenCountryCode(form, selectedCountry.iso2)
  }
  if (countryDropdown) {
    attachDropdownEvent(countryDropdown, instanceMap, form)
  }
}

const setDefaultDialCode = (input, iti) => {
  const selectedCountry = iti.getSelectedCountryData()
  if (!input.value.trim() && selectedCountry && selectedCountry.dialCode) {
    input.value = `+${selectedCountry.dialCode} `
  }
}

const attachInputEvents = (input, iti, countryDropdown, form) => {
  input.addEventListener('countrychange', () => {
    setTimeout(() => {
      const selectedCountry = iti.getSelectedCountryData()
      const expectedDialCodeStr = `+${selectedCountry.dialCode}`
      if (input.value.trim().length > expectedDialCodeStr.length) {
        const currentValue = input.value.replace(/^\+\d+\s*/, '').trim()
        input.value = `+${selectedCountry.dialCode} ${currentValue}`
      }
      if (countryDropdown) {
        countryDropdown.value = selectedCountry.name
      }
      updateHiddenCountryCode(form, selectedCountry.iso2)
    }, 10)
  })

  input.addEventListener('focus', () => {
    if (!input.value.trim()) {
      const selectedCountry = iti.getSelectedCountryData()
      if (selectedCountry && selectedCountry.dialCode) {
        input.value = `+${selectedCountry.dialCode} `
      }
    }
  })

  input.addEventListener('input', () => {
    const trimmedValue = input.value.trim()
    if (trimmedValue === '' || trimmedValue === '+') return
    const selectedCountry = iti.getSelectedCountryData()
    const expectedDialCodeStr = `+${selectedCountry.dialCode}`
    if (trimmedValue.length < expectedDialCodeStr.length) return
    if (!input.value.startsWith('+')) {
      input.value = `+${trimmedValue}`
    }
    const dialCodeMatch = input.value.match(/^\+(\d{1,4})/)
    if (dialCodeMatch) {
      const enteredDialCode = dialCodeMatch[1]
      const matchedCountry = getCountryData().find(
        country => country.dialCode === enteredDialCode
      )
      if (matchedCountry) {
        iti.setCountry(matchedCountry.iso2)
        if (countryDropdown) {
          countryDropdown.value = matchedCountry.name
        }
        updateHiddenCountryCode(form, matchedCountry.iso2)
      }
    }
  })
}

const attachDropdownEvent = (dropdown, instanceMap, form) => {
  dropdown.addEventListener('change', () => {
    const selectedCountryName = dropdown.value
    const allCountries = getCountryData()
    instanceMap.forEach(({ input, iti }) => {
      const countryData = allCountries.find(
        country => country.name === selectedCountryName
      )
      if (countryData) {
        iti.setCountry(countryData.iso2)
        const currentValue = input.value.replace(/^\+\d+\s*/, '').trim()
        input.value = `+${countryData.dialCode} ${currentValue}`
        updateHiddenCountryCode(form, countryData.iso2)
      }
    })
  })
}

const updateHiddenCountryCode = (form, newCountryCode) => {
  const hiddenInputs = form.querySelectorAll('input[type="hidden"]')
  hiddenInputs.forEach(input => {
    if (input.value === 'Country Code') {
      input.value = newCountryCode
    }
  })
}
