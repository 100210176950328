// Initialize cookie popup functionality
export function initCookiePopup () {
  const popup = document.querySelector('.cookie-popup')
  const acceptButton = document.getElementById('acceptCookies')
  const closeButton = document.getElementById('closePopup')

  // Correct the logic to check if cookiesAccepted or cookiesRejected exist
  if (!getCookie('cookiesAccepted') && !getCookie('cookiesRejected')) {
    popup.style.display = 'block' // Show the popup only if neither cookie is set
  } else {
    popup.style.display = 'none' // Ensure the popup is hidden otherwise
  }

  // Handle Accept button
  acceptButton.addEventListener('click', () => {
    setCookie('cookiesAccepted', 'true', 365) // User accepted cookies
    popup.style.display = 'none'
  })

  // Handle Close button
  closeButton.addEventListener('click', () => {
    setCookie('cookiesRejected', 'true', 365) // User rejected cookies
    popup.style.display = 'none'
  })
}

// Set a cookie
function setCookie (name, value, days) {
  const date = new Date()
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
  document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`
}

// Get a cookie
function getCookie (name) {
  const cookies = document.cookie.split(';')
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim()
    if (cookie.startsWith(`${name}=`)) {
      return cookie.substring(name.length + 1)
    }
  }
  return null
}
