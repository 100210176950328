export function initDisclaimer () {
  const disclaimer = document.getElementById('disclaimer')
  const disclaimerClose = document.getElementById('disclaimerClose')
  if (sessionStorage.getItem('disclaimerHidden') !== 'true') {
    disclaimer.style.display = 'flex'
  }
  disclaimerClose.addEventListener('click', () => {
    disclaimer.style.display = 'none'
    sessionStorage.setItem('disclaimerHidden', 'true')
  })
}
