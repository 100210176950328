// Select the buttons and sidebar
const mobileMenuButton = document.querySelector('.header__menu-btn-mobile')
const mainMenuButton = document.querySelector('.header__menu-btn')
const sidebar = document.querySelector('.header__nav')

export function initializeHeaderNavigation () {
  if (!mobileMenuButton || !mainMenuButton || !sidebar) {
    console.error(
      'Required elements not found: .header__menu-btn-mobile, .header__menu-btn, or .header__nav'
    )
    return
  }

  // Event listener to remove the "open" class from the sidebar
  mobileMenuButton.addEventListener('click', () => {
    sidebar.classList.remove('open')
  })

  // Event listener to add the "open" class to the sidebar
  mainMenuButton.addEventListener('click', () => {
    sidebar.classList.add('open')
  })
}
