export function floatingWidgetTranstion () {
  const footer = document.querySelector('footer')
  const whatsappFloat = document.querySelector('.whatsapp-float')

  if (!footer || !whatsappFloat) return

  const observer = new IntersectionObserver(
    ([entry]) => {
      whatsappFloat.classList.toggle(
        'floating-transition',
        entry.isIntersecting
      )
    },
    { threshold: 0.1 }
  )

  observer.observe(footer)
}
