import { initCookiePopup } from './modular/cookiesManager.js'
import { initPhoneInputs } from './modular/initTelInput.js'
import { initializeHeaderNavigation } from './modular/headerNavigation.js'
import { floatingWidgetTranstion } from './modular/floatingWidget.js'
import { initDisclaimer } from './modular/disclamerManager.js'

document.addEventListener('DOMContentLoaded', () => {
  initCookiePopup()
  initPhoneInputs()
  initializeHeaderNavigation()
  floatingWidgetTranstion()
  initDisclaimer()

  document
    .getElementById('contactUsFormPopup')
    .addEventListener('click', function (e) {
      if (e.target === this) {
        closePopup()
      }
    })
})

window.openPopup = () => {
  document.getElementById('contactUsFormPopup').style.display = 'flex'
}

window.closePopup = () => {
  document.getElementById('contactUsFormPopup').style.display = 'none'
}

window.openPopupDemo = () => {
  document.getElementById('contactUsFormPopupDemo').style.display = 'flex'
}

window.closePopupDemo = () => {
  document.getElementById('contactUsFormPopupDemo').style.display = 'none'
}
